export const mixin = {
  data() {
    return {
      ishome: true,
      messageleo: "Hello qiaohui!",
      appInfo: {},
      loading: true,
      downIosFlag: false,
      msg: "",
      downloadFlag: false,
      pwd: "",
      pwdMsg: false,
      // pwdTxt: null,
      pwdTxt: "",
      reportFlag: false,
      reportname: "",
      reportMobile: "",
      reportContent: "",

      mobile: "",
      username: "",
      sf: "",
      title: "",
      cache: "",
      pcFlag: false,
      qrcode: "",
      baseUrl: window.location.origin || "https://jzyx.ink", // 测试服：https://administrators.yunqianhui.com.cn  正式服：https://fzapp.jzyweb.ink   英文版后台地址 https://a.qweapp.com
      // baseUrl: "https://test.ejuzhen.com",
      uploadtoken: "",
      language: "chinese",
      mobileAd: {},
      mobileAdShow: true,
      pcAd: {},
      pcAdShow: true,
      hongbaoAd: {},
      jumpObject: {},
      whiteList: "",
      validateInfo: {
        num1: 0,
        num2: 0,
        result: -1,
      },
      modalShow: false,
      result: "",
    };
  },
  created() {
    if (process.env.NODE_ENV == "development") {
      this.baseUrl = "https://fzapp.jzyweb.ink";
    }
    const language = navigator.language;
    if (language == "zh-CN") {
      this.$i18n.locale = "zh";
    } else if (language == "en") {
      this.$i18n.locale = "en";
    } else if (language == "zh-TW") {
      this.$i18n.locale = "tw";
    } else if (language == "vi") {
      //越南语
      this.$i18n.locale = "vi";
    }
    this.getAonList();
  },
  mounted() {
    var isWeixin = this.is_weixin();
    var cssText =
      "#weixin-tip{position: fixed; left:0; top:0; background: rgb(0,0,0); width: 100%; height:100%; z-index: 10000;opacity:.7;} #weixin-tip p{text-align: center; margin-top: 10%; padding:0 5%;}";
    if (isWeixin) {
      this.loadHtml();
      this.loadStyleText(cssText);
    }
    window.addEventListener("resize", () => {
      if (window.innerWidth <= 768) {
        this.pcFlag = false;
      } else {
        this.pcFlag = true;
      }
    });

    this.init();
  },
  watch: {
    modalShow(show) {
      if (show) {
        let num1 = this.getRandom(1, 49);
        let num2 = this.getRandom(1, 49);
        this.validateInfo.num1 = num1;
        this.validateInfo.num2 = num2;
        this.validateInfo.result = Number(parseFloat(num1 + num2).toFixed(2));
      }
    },
  },
  methods: {
    getRandom(min = 1, max = 49) {
      return Math.floor(Math.random() * (max - min + 1) + min);
    },
    jumpUrl(url) {
      if (url && url.includes("http")) {
        window.open(url);
      }
    },
    toggleBack() {
      this.ishome = !this.ishome;
    },
    getAonList() {
      this.$axios({
        method: "GET",
        url: this.baseUrl + "/api/aon/aon_list",
      }).then((res) => {
        if (res.status == 200) {
          if (res.data.code == 1) {
            res.data.data.forEach((item) => {
              if (item.web === "mobile") {
                this.mobileAd = item;
              }
              if (item.web === "hongbao") {
                this.hongbaoAd = item;
              }
              if (item.web === "pc_dowload") {
                this.pcAd = item;
              }
            });
          } else {
          }
        }
      });
      this.$axios({
        method: "GET",
        url: this.baseUrl + "/api/aon/system_info",
      }).then((res) => {
        if (res.status == 200) {
          if (res.data.code === 1) {
            this.jumpObject = res.data.data.find((item) => {
              return item.name === "hongbao_url";
            });
            this.whiteList = res.data.data.find((item) => {
              return item.name === "aonlist";
            }).value;
          }
        }
      });
    },
    loadStyleText(cssText) {
      var style = document.createElement("style");
      style.rel = "stylesheet";
      style.type = "text/css";
      try {
        style.appendChild(document.createTextNode(cssText));
      } catch (e) {
        style.styleSheet.cssText = cssText; //ie9以下
      }
      var head = document.getElementsByTagName("head")[0]; //head标签之间加上style样式
      head.appendChild(style);
    },
    loadHtml() {
      var div = document.createElement("div");
      div.id = "weixin-tip";
      div.innerHTML = '<p><img src="./point.png" style="width: 100%;" /></p>'; //遮罩图片
      document.body.appendChild(div);
    },
    getQrCode(id) {
      const params = {
        id: id,
      };
      const formData = new FormData();
      Object.keys(params).forEach((key) => {
        formData.append(key, params[key]);
      });
      this.$axios({
        method: "post",
        url: this.baseUrl + "/api/qnupload/AppPrcode",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: formData,
      })
        .then(({ data }) => {
          if (data.code === 1) {
            this.pcFlag = true;
            this.qrcode = data.data;
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    saveReport() {
      this.loading = true;
      const params = {
        username: this.username,
        sf: this.sf,
        title: this.title,
        cache: this.cache,
        mobile: this.mobile,
        appid: this.appInfo.id,
      };
      const formData = new FormData();
      Object.keys(params).forEach((key) => {
        formData.append(key, params[key]);
      });
      this.$axios({
        method: "post",
        url: this.baseUrl + "/api/Tfsign/report",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: formData,
      })
        .then(({ data }) => {
          this.loading = false;
          if (data.code === 1) {
            this.reportFlag = false;
            this.$toast.message({
              close: false,
              position: "top",
              message: this.$t("index.jbcg"),
            });
          } else {
            this.$toast.message({
              close: false,
              position: "top",
              message: this.$t("index.jbsb"),
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    sendSms() {
      // 发送验证码
      if (!this.mobile) {
        this.$toast.message({
          close: false,
          position: "top",
          message: this.$t("index.qssjh"),
        });
        return;
      }
      const params = {
        mobile: this.mobile,
        event: "report",
      };
      const formData = new FormData();
      Object.keys(params).forEach((key) => {
        formData.append(key, params[key]);
      });
      this.$axios({
        method: "post",
        url: this.baseUrl + "/api/Sms/send",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: formData,
      })
        .then(({ data }) => {
          if (data.code === 1) {
            this.$toast.message({
              close: false,
              position: "top",
              message: this.$t("index.hqcg"),
            });
          } else {
            this.$toast.message({
              close: false,
              position: "top",
              message: this.$t("index.hqsb"),
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    closeReport() {
      this.reportFlag = false;
    },
    report() {
      this.reportFlag = true;
    },
    tijiao() {
      this.pwdMsg = this.pwd !== this.appInfo.uploadpwd;
      this.pwdTxt = this.pwd;
    },
    downIosFun() {
      this.$axios({
        method: "GET",
        url: this.baseUrl + "/api/qnupload/ces",
        // headers: {
        // 	"Access-Control-Allow-Origin": "*",
        // 	'Content-Type': 'application/x-www-form-urlencoded',
        // },
        data: {
          id: window.location.pathname.slice(1),
          // id: 'c6PaXV'
        },
      })
        .then(({ data }) => {
          window.location.href = "itms-services://?action=download-manifest&url=" + data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      var r = decodeURI(window.location.search.substr(1)).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
    confirm() {
      if (this.result && Number(this.result) === this.validateInfo.result) {
        this.installApp(true);
      } else {
        this.result = "";
      }
    },
    installApp(validate = false) {
      if (this.home == 1) {
        if (!validate) {
          if (this.appInfo.need_validate == 2) {
            this.modalShow = true;
            return;
          } else {
            this.modalShow = false;
          }
        } else {
          this.modalShow = false;
        }

        this.result = "";
        if (this.whiteList.indexOf(this.appInfo.user_id) === -1) {
          this.ishome = false;
        }
      } else {
        if (this.downloaded) {
          return
        }
        this.downloaded = false
      }
      this.downIosFlag = false;
      this.loading = true;
      const params = {
        id: this.appInfo.id,
        qiniutoken: this.uploadtoken,
        code: this.getQueryString("code"),
      };
      const formData = new FormData();
      Object.keys(params).forEach((key) => {
        formData.append(key, params[key]);
      });
      this.$axios({
        method: "post",
        url: this.baseUrl + "/api/qnupload/DecUpload",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: formData,
      })
        .then(({ data }) => {
          this.loading = false;

          if (data.code === 1) {
            this.downloadFlag = true;
            setTimeout(() => {
              this.downloaded = true
            }, 2000)
            if (this.appInfo.mimetype === "Android") {
              window.open(this.appInfo.url);
              return;
            } else if (this.appInfo.mimetype === "iOS") {
              this.downIosFlag = true;
              window.location =
                "itms-services://?action=download-manifest&url=" +
                window.encodeURIComponent(
                  this.baseUrl + "/api/qnupload/PlistDownLoad?id=" + this.appInfo.onlyf
                );
            } else {
              window.open(this.appInfo.url);
            }
          } else {
            this.$toast.message({
              close: false,
              position: "top",
              message: data.msg,
            });
          }

        })
        .catch((error) => {
          console.log(error);
        });
    },
    queryQiniuToken(fields) {
      let bucket = "";
      if (fields.mimetype == "Android") {
        // 安卓
        bucket = "jz-apkk";
      } else if (fields.mimetype == "iOS") {
        // IOS
        bucket = "jz-ios";
      } else {
        // IOS免签 Web Clip
        bucket = "jz-mobileconfig";
      }
      const params = {
        bucket: bucket,
      };
      const formData = new FormData();
      Object.keys(params).forEach((key) => {
        formData.append(key, params[key]);
      });
      this.$axios({
        method: "post",
        url: this.baseUrl + "/api/qnupload/GetQiNiuToken",
        data: formData,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then(({ data }) => {
          if (data.code === 1) {
            this.uploadtoken = data.data.uploadtoken;
          } else {
            this.msg = data.msg;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    is_weixin() {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
      } else {
        return false;
      }
    },
    ismobile(test) {
      var u = navigator.userAgent,
        app = navigator.appVersion;
      if (
        /AppleWebKit.*Mobile/i.test(navigator.userAgent) ||
        /MIDP|SymbianOS|NOKIA|SAMSUNG|LG|NEC|TCL|Alcatel|BIRD|DBTEL|Dopod|PHILIPS|HAIER|LENOVO|MOT-|Nokia|SonyEricsson|SIE-|Amoi|ZTE/.test(
          navigator.userAgent
        )
      ) {
        if (window.location.href.indexOf("?mobile") < 0) {
          try {
            if (/iPhone|mac|iPod|iPad/i.test(navigator.userAgent)) {
              return "0";
            } else {
              return "1";
            }
          } catch (e) { }
        }
      } else if (u.indexOf("iPad") > -1) {
        return "0";
      } else {
        return "1";
      }
    },
    init() {
      this.pcFlag = false;
      const params = {
        id: this.$route.params.id,
      };
      const formData = new FormData();
      Object.keys(params).forEach((key) => {
        formData.append(key, params[key]);
      });
      this.$axios({
        method: "post",
        url: this.baseUrl + "/api/qnupload/GetPlistInfo",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: formData,
      })
        .then(({ data }) => {
          // data.code = 1;
          // data.data = {
          //   "id": 22828,
          //   "user_id": 102,
          //   "filename": "14c2cf51-6826-4bf8-8b5f-3be862702bff.apk",
          //   "filesize": "19.4M",
          //   "mimetype": "Android",
          //   "extparam": "",
          //   "createtime": "2024-06-30 19:15:12",
          //   "updatetime": "2024-07-01 23:23:44",
          //   "uploadtime": 1719746112,
          //   "storage": "qiniu",
          //   "sha1": "nl3BvY3Lt5ZblnHjU7eOjnLgY6Z4",
          //   "plist": null,
          //   "url": "https:\/\/apkk.qjuzhen.com\/14c2cf51-6826-4bf8-8b5f-3be862702bff.apk?e=1719851029&token=Z2_Ygatc6VrZwpw-7I6arf4lgVPQ2BXZCfDKcGMh:rBovQwZ7S6jVl_mLEuSiC2gkALE=",
          //   "image": "https:\/\/imgg.jzy.ink\/Fl2A2os_g9Y8LP3XN0uGZTqzfX23?e=1719851029&token=Z2_Ygatc6VrZwpw-7I6arf4lgVPQ2BXZCfDKcGMh:gqi2j070KZM0p9FFmasK-rh1x1s=",
          //   "identifier": "com.github.metacubex.clash.meta",
          //   "version": "210000",
          //   "ipa_name": "Clash Meta for Android",
          //   "fileinfo": "武切维奇",
          //   "status": "show",
          //   "uploadnum": 14,
          //   "support_url": "https:\/\/web.okpan.com\/index",
          //   "copyright": "矩阵应用",
          //   "logmassage": "更新内容123123123123",
          //   "uploadpwd": "",
          //   "need_validate": 1,
          //   "preview": [
          //     "https:\/\/xq.neicehu.cn\/A589EDC7EC02A5AC1719793610000.png",
          //     "https:\/\/xq.neicehu.cn\/8515D4ECC5CD67791719793610000.png"
          //   ],
          //   "teamname": "",
          //   "xlversion": "2.10.0.Meta",
          //   "erweima": "https:\/\/ewm.jzy.ink\/dbc151a7c8846c0572c7f38db8c00e3a.png",
          //   "onlyf": "jKBPok",
          //   "switch": "0",
          //   "downsize": 999999,
          //   "overduetime": null,
          //   "appurl": null,
          //   "isapply": "2",
          //   "isdown": "1",
          //   "isadvertisingisaon": null,
          //   "type": "app",
          //   "template": 0,
          //   "language": "vietnam",
          //   "download_error": "",
          //   "thumb_style": "",
          //   "isadvertisingisaon_status": "1",
          //   "point": 315488
          // }
          this.loading = false;
          // this.$emit('toggleComponent', 2)
          if (data.data.template && data.data.template == 2) {
            this.$emit('toggleComponent', 2)
          }
          // document.title = data.data ? data.data.ipa_name : "";
          if (data.data && data.data !== "undefined") {
            this.language = data.data.language;
            // this.$i18n.locale = this.language === "eng" ? 'en' : 'zh';
            switch (this.language) {
              case "eng":
                this.$i18n.locale = "en";
                break;
              case "tw":
                this.$i18n.locale = "tw";
                break;
              case "taiwan":
                this.$i18n.locale = "tw";
                break;
              case "chinese":
                this.$i18n.locale = "zh";
                break;
              case "vietnam":
                this.$i18n.locale = "vi";
                break;
              default:
                this.$i18n.locale = "zh";
                break;
            }
          }
          // PC端显示二维码逻辑
          if (
            navigator.userAgent.match(
              /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
            )
          ) {
            // 移动端
          } else {
            var ua = navigator.userAgent;
            if (
              ua ==
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_6) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.0 Safari/605.1.15" ||
              ua ==
              "Mozilla/5.0 (iPhone; CPU iPhone OS 14_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/14.0.1 Mobile/15E148 Safari/604.1" ||
              ua ==
              "Mozilla/5.0 (iPhone; CPU iPhone OS 15_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.1 Mobile/15E148 Safari/604.1" ||
              ua ==
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_6) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/14.1.2 Safari/605.1.15" ||
              ua ==
              "Mozilla/5.0 (iPhone; CPU iPhone OS 13_5_1 like Mac OS X; zh-CN) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/17F80 UCBrowser/13.5.8.1568 Mobile  AliApp(TUnionSDK/0.1.20.4)" ||
              ua ==
              "Mozilla/5.0 (iPhone; CPU iPhone OS 15_0_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.0 Mobile/15E148 Safari/604.1" ||
              ua ==
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/13.0.5 Safari/605.1.15" ||
              ua ==
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_4) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/13.1.2 Safari/605.1.15" ||
              ua ==
              "Mozilla/5.0(Macintosh:IntelMacOSX1015 6)AppleWebKit/605.1.15(KHTML like Gecko) Version/14.1.1 Safari/605.1.15" ||
              ua ==
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_6) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.4 Safari/605.1.15" ||
              ua ==
              "Mozilla/5.0 (Linux; Android 10; TAS-AL00; HMSCore 6.4.0.311) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.105 HuaweiBrowser/12.1.0.301 Mobile Safari/537.36" ||
              ua ==
              "Mozilla/5.0 (Linux; Android 10; PCT-AL10 Build/HUAWEIPCT-AL10; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/83.0.4103.106 Mobile Safari/537.36" ||
              ua ==
              "Mozilla/5.0 (Linux; Android 11; V2046A Build/RP1A.200720.012; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/76.0.3809.89 Mobile Safari/537.36 T7/13.12 SP-engine/2.52.0 baiduboxapp/13.14.0.11 (Baidu; P1 11) NABar/1.0" ||
              ua ==
              "Mozilla/5.0 (Windows; U; Windows NT 5.2; en-US) AppleWebKit/537.36 (KHTML, like Gecko) Safari/537.36 VivoBrowser/11.8.5.6 Chrome/87.0.4280.141" ||
              ua ==
              "Mozilla/5.0 (Linux; Android 11; VNE-AN00 Build/HONORVNE-AN00; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/86.0.4240.99 XWEB/4317 MMWEBSDK/20220903 Mobile Safari/537.36 MMWEBID/931 MicroMessenger/8.0.28.2240(0x28001C57) WeChat/arm64 Weixin NetType/WIFI Language/zh_CN ABI/arm64"
            ) {
              // 移动端
            } else {
              // PC端
              this.getQrCode(data.data.id);
            }
          }
          if (data.code === 1) {
            this.appInfo = data.data;
            this.$nextTick(() => {
            });
            this.queryQiniuToken(this.appInfo);
          } else if (data.code === 2) {
            var pla = ismobile(1);
            if (pla == 0 && data.data) {
              // 苹果手机
              window.location.href = data.data;
            }
          } else {
            if (data.code === 3) {
              this.msg =
                this.language === "eng"
                  ? "This app developer does not have a real-name, please conduct a real-name authentication as soon as possible"
                  : "此应用开发者未实名，请尽快进行实名认证";
              return;
            } else if (data.code === 4) {
              this.msg =
                this.language === "eng"
                  ? "This app does not comply with the platform rules and is temporarily removed."
                  : "此应用不符合平台规则，暂时下架。";
              return;
            } else if (data.code === 5) {
              this.msg =
                this.language === "eng"
                  ? "This application has not been downloaded enough times, please recharge, refresh and try again"
                  : "此应用下载次数不足，请充值后刷新重试";
              return;
            }
            this.msg = data.msg;
          }
        })
    },
  },
}